// @flow
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILED,
  CONFIRM_USER_ACCOUNT,
  CONFIRM_USER_ACCOUNT_SUCCESS,
  CONFIRM_USER_ACCOUNT_FAILED,
} from "../../constants/actionTypes";

type AuthAction = { type: string, payload: {} | string };

export const loginUser = (username: string, password: string): AuthAction => ({
  type: LOGIN_USER,
  payload: { username, password },
});

export const loginUserSuccess = (user: string): AuthAction => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});

export const loginUserFailed = (login_user_error: string): AuthAction => ({
  type: LOGIN_USER_FAILED,
  payload: login_user_error,
});

export const registerUser = (
  user_name: string,
  username: string,
  email: string,
  first_name: string,
  last_name: string,
  organization: string,
  password: string
): AuthAction => ({
  type: REGISTER_USER,
  payload: {
    user_name,
    username,
    email,
    first_name,
    last_name,
    organization,
    password,
  },
});

export const registerUserSuccess = (user: {}): AuthAction => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});

export const registerUserFailed = (error: string): AuthAction => ({
  type: REGISTER_USER_FAILED,
  payload: error,
});

export const logoutUser = (history: any): AuthAction => ({
  type: LOGOUT_USER,
  payload: { history },
});

export const forgetPassword = (username: string): AuthAction => ({
  type: FORGET_PASSWORD,
  payload: { username },
});

export const forgetPasswordSuccess = (
  passwordResetStatus: string
): AuthAction => ({
  type: FORGET_PASSWORD_SUCCESS,
  payload: passwordResetStatus,
});

export const forgetPasswordFailed = (error: string): AuthAction => ({
  type: FORGET_PASSWORD_FAILED,
  payload: error,
});

export const confirmUserAccount = (
  username: string,
  code: string
): AuthAction => ({
  type: CONFIRM_USER_ACCOUNT,
  payload: { username, code },
});

export const confirmUserAccountFailed = (
  confirm_account_error: string
): AuthAction => ({
  type: CONFIRM_USER_ACCOUNT_FAILED,
  payload: confirm_account_error,
});

export const confirmUserAccountSuccess = (confirmUserAccount: {}): AuthAction => ({
  type: CONFIRM_USER_ACCOUNT_SUCCESS,
  payload: confirmUserAccount,
});
