/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";
export const REGISTER_USER = "REGISTER_USER";
export const CONFIRM_USER_ACCOUNT = "CONFIRM_USER_ACCOUNT";
export const CONFIRM_USER_ACCOUNT_SUCCESS = "CONFIRM_USER_ACCOUNT_SUCCESS";
export const CONFIRM_USER_ACCOUNT_FAILED = "CONFIRM_USER_ACCOUNT_FAILED";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAILED = "FORGET_PASSWORD_FAILED";
export const CHANGE_FIRST_NAME = "CHANGE_FIRST_NAME";
export const CHANGE_LAST_NAME = "CHANGE_LAST_NAME";
export const CHANGE_TOTAL_LICENSE_NUMBER = "CHANGE_TOTAL_LICENSE_NUMBER";
export const CHANGE_TIER_ONE_DEVICE_LICENSE_NUMBER =
  "CHANGE_TIER_ONE_DEVICE_LICENSE_NUMBER";
