// @flow
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILED,
  CONFIRM_USER_ACCOUNT,
  CONFIRM_USER_ACCOUNT_SUCCESS,
  CONFIRM_USER_ACCOUNT_FAILED,
  CHANGE_FIRST_NAME,
  CHANGE_LAST_NAME,
  CHANGE_TOTAL_LICENSE_NUMBER,
  CHANGE_TIER_ONE_DEVICE_LICENSE_NUMBER,
} from "../../constants/actionTypes";

import { getLoggedInUser } from "../../helpers/authUtils";

const INIT_STATE = {
  user: getLoggedInUser(),
  loading: false,
};

type AuthAction = { type: string, payload: {} | string };
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const Auth = (state: State = INIT_STATE, action: AuthAction) => {
  const newState = { ...state };
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true };
    case LOGIN_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case LOGIN_USER_FAILED:
      return { ...state, login_user_error: action.payload, loading: false };
    case REGISTER_USER:
      return { ...state, loading: true };
    case REGISTER_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case REGISTER_USER_FAILED:
      return { ...state, error: action.payload, loading: false };
    case CONFIRM_USER_ACCOUNT:
      return { ...state, loading: true };
    case CONFIRM_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        confirmed_user: action.payload,
        loading: false,
        error: null,
      };
    case CONFIRM_USER_ACCOUNT_FAILED:
      return {
        ...state,
        confirm_account_error: action.payload,
        loading: false,
      };
    case LOGOUT_USER:
      return { ...state, user: null };
    case FORGET_PASSWORD:
      return { ...state, loading: true };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordResetStatus: action.payload,
        loading: false,
        error: null,
      };
    case FORGET_PASSWORD_FAILED:
      return { ...state, error: action.payload, loading: false };
    case CHANGE_FIRST_NAME: {
      newState.user.first_name = action.first_name;
      return { ...newState };
    }
    case CHANGE_LAST_NAME: {
      newState.user.last_name = action.last_name;
      return { ...newState };
    }
    case CHANGE_TOTAL_LICENSE_NUMBER: {
      newState.user.total_licenses = action.total_licenses;
      return { ...newState };
    }
    case CHANGE_TIER_ONE_DEVICE_LICENSE_NUMBER: {
      newState.user.tier_1_devices = action.tier_1_devices;
      return { ...newState };
    }
    default:
      return { ...state };
  }
};

export default Auth;
