import { ENDPOINT } from "./config/constants";
import axios from "axios";

export const isAuthenticated = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}isAuthenticated`, JSON.parse(request), {
        withCredentials: true,
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const setCookie = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}setCookie`, JSON.parse(request), {
        withCredentials: true,
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const changeUserAttributes = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}changeUserAttributes`, JSON.parse(request))
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const listUsers = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}listUsers`, request, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const checkUsernameAvailability = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}checkUsernameAvailability`, request, {
        withCredentials: true,
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const sendOrganizationCreationMail = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}sendOrganizationCreationMail`, request)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const addServer = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}addServer`, request, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const deleteServer = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}deleteServer`, request, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getServers = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}getServers`, request, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const syncHost = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}syncHost`, request, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const validateToken = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}validateToken`, request)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const validateResetToken = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}validateResetToken`, request)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const listSharedRoasterGroups = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}listSharedRoasterGroups`, request, {
        withCredentials: true,
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const listSharedRoasterGroupsByOrganization = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}listSharedRoasterGroupsByOrganization`, request, {
        withCredentials: true,
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const listOrganizations = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}listOrganizations`, request, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const listVirtualHosts = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}listVirtualHosts`, request, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const listAvailableVirtualHostsForOrganization = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}listAvailableVirtualHostsForOrganization`, request, {
        withCredentials: true,
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const adminCreateUser = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}adminCreateUser`, request, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const listVirtualHostsWithStatus = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}listVirtualHostsWithStatus`, request, {
        withCredentials: true,
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const createTeam = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}createTeam`, request)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const addOrganization = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}addOrganization`, request, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const updateOrganizationAttributes = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}updateOrganizationAttributes`, request, {
        withCredentials: true,
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getOrganizations = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}getOrganizations`, {}, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getVirtualhosts = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}getVirtualhosts`, {}, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const enableUser = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}enableUser`, JSON.parse(request))
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const disableUser = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}disableUser`, JSON.parse(request))
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const bulkDeactivate = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}bulkDeactivate`, JSON.parse(request))
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const verifyUser = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}verifyUser`, {}, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const logOut = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}logOut`, {}, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const addUser = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}addUser`, request, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const updateUserAttribute = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}updateUserAttribute`, request, {
        withCredentials: true,
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const resetUserPassword = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}resetUserPassword`, request, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const deleteUser = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}deleteUser`, request, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const bulkDelete = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}bulkDelete`, JSON.parse(request), {
        withCredentials: true,
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const createRoasterTeam = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}createRoasterTeam`, request, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const deleteRoasterTeam = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}deleteRoasterTeam`, request, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const addUsersToRoasterTeam = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}addUsersToRoasterTeam`, request, {
        withCredentials: true,
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getUsersFromRoasterTeam = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}getUsersFromRoasterTeam`, request, {
        withCredentials: true,
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const deleteUsersFromRoasterTeam = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}deleteUsersFromRoasterTeam`, request, {
        withCredentials: true,
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const deleteOrganization = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}deleteOrganization`, request, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const updateUserDetails = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}updateUserDetails`, request, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const updateUserName = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}updateUserName`, request, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const updateUserRole = (request) => {
  console.log("Inside updateUserRole");
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}updateUserRole`, request, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
export const getOrganization = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}getOrganization`, request, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getUserDetailsFromDb = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}getUserDetailsFromDb`, request, {
        withCredentials: true,
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const confirmUser = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}confirmUser`, request, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const confirmEmail = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}confirmEmail`, request, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const updateUserEmail = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}updateUserEmail`, request, { withCredentials: true })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
export const sendInvite = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}sendInvite`, request)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const updateEnableTrialStatus = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}updateEnableTrialStatus`, request)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getServer = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ENDPOINT}getServer`, request)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
