import React from "react";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";
// Other components

import { isUserAuthenticated, getLoggedInUser } from "./helpers/authUtils";

function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, interval, retriesLeft - 1).then(resolve, reject);
        }, interval);
      });
  });
}

// lazy load all the views
const Dashboard = React.lazy(() => retry(() => import("./pages/Dashboard")));

// auth

const Login = React.lazy(() => retry(() => import("./pages/auth/Login")));
const Logout = React.lazy(() => retry(() => import("./pages/auth/Logout")));
const ForgetPassword = React.lazy(() =>
  retry(() => import("./pages/account/ForgetPassword"))
);
const Register = React.lazy(() =>
  retry(() => import("./pages/account/Register"))
);
const ConfirmAccount = React.lazy(() =>
  retry(() => import("./pages/account/Confirm"))
);
const ConfirmAccountForSignIn = React.lazy(() =>
  retry(() => import("./pages/account/ConfirmAccountForSignIn"))
);

const Account = React.lazy(() => retry(() => import("./components/Account")));
const ManageAdmins = React.lazy(() =>
  retry(() => import("./components/ManageAdmins"))
);
const UserGroups = React.lazy(() =>
  retry(() => import("./components/UserGroups"))
);
const Organizations = React.lazy(() =>
  retry(() => import("./components/Organizations"))
);
const VirtualHosts = React.lazy(() =>
  retry(() => import("./components/VirtualHosts"))
);
// const ResetPassword = React.lazy(() => retry(() => import ('./pages/account/ResetPassword')));
const ForceResetPassword = React.lazy(() =>
  retry(() => import("./components/ResetPassword"))
);
const SubmitForgotPassword = React.lazy(() =>
  retry(() => import("./components/SubmitForgotPassword"))
);
const CreateTeam = React.lazy(() =>
  retry(() => import("./components/CreateTeam"))
);

// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const isAuthTokenValid = isUserAuthenticated();
      if (!isAuthTokenValid) {
        // not logged in so redirect to login page with the return url
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }

      const loggedInUser = getLoggedInUser();
      // check if route is restricted by role
      if (roles && roles.indexOf(loggedInUser.role) === -1) {
        // role not authorised so redirect to home page
        return <Redirect to={{ pathname: "/" }} />;
      }

      // authorised so return component
      return <Component {...props} />;
    }}
  />
);

const routes = [
  // auth and account
  { path: "/login", name: "Login", component: Login, route: Route },
  { path: "/logout", name: "Logout", component: Logout, route: Route },
  {
    path: "/forget-password",
    name: "Forget Password",
    component: ForgetPassword,
    route: Route,
  },
  // { path: '/reset-password', name: 'Reset Password', component: ResetPassword, route: Route },
  { path: "/register", name: "Register", component: Register, route: Route },
  {
    path: "/confirm",
    name: "Confirm",
    component: ConfirmAccount,
    route: Route,
  },
  {
    path: "/confirm-account",
    name: "Confirm",
    component: ConfirmAccountForSignIn,
    route: Route,
  },
  // other pages
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    route: PrivateRoute,
    roles: ["Admin"],
    title: "Dashboard",
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
    route: PrivateRoute,
    title: "Account",
  },
  {
    path: "/manage-admins",
    name: "Manage Admins",
    component: ManageAdmins,
    route: PrivateRoute,
    title: "Manage Users",
  },
  {
    path: "/user-groups",
    name: "User Groups",
    component: UserGroups,
    route: PrivateRoute,
    title: "Manage Teams",
  },
  {
    path: "/organizations",
    name: "Manage Organizations",
    component: Organizations,
    route: PrivateRoute,
    title: "Manage Organizations",
  },
  {
    path: "/virtual-hosts",
    name: "Virtual Hosts",
    component: VirtualHosts,
    route: PrivateRoute,
    title: "Manage Virtual Hosts",
  },
  {
    path: "/validate/:userToken",
    name: "Force Reset Password",
    component: ForceResetPassword,
    route: Route,
  },
  {
    path: "/reset/:resetToken",
    name: "Reset password",
    component: SubmitForgotPassword,
    route: Route,
  },
  { path: "/trial", name: "Create Team", component: CreateTeam, route: Route },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute,
  },
  {
    path: "/support",
    exact: true,
    component: () =>
      (window.location.href = "https://glaciersecurity.zendesk.com/hc/en-us"),
    route: Route,
  },
];

export { routes, PrivateRoute };
