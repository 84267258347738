import { Auth } from "aws-amplify";
import { addUser, setCookie, getUserDetailsFromDb } from "./../apis";

export function configureFakeBackend() {
  let users = [];

  // let users = [{ id: 1, username: 'test', password: 'test', firstName: 'Test', lastName: 'User',phone_number : "testnumber",email : "testnumber", role: 'Admin' }];
  let realFetch = window.fetch;
  window.fetch = function (url, opts) {
    return new Promise((resolve, reject) => {
      // wrap in timeout to simulate server api call
      // setTimeout(() => {

      // authenticate
      if (url.endsWith("/users/authenticate") && opts.method === "POST") {
        // get parameters from post request
        let params = JSON.parse(opts.body);
        let userDetails;
        let { username, password } = params;
        Auth.signIn({ username, password })
          .then((user) => (userDetails = user))
          .then(() => {
            // Logic here on successfull signin
            // if login details are valid return user details and fake jwt token

            //let user = users[0];
            let responseJson = {
              id: userDetails.attributes["sub"],
              username: userDetails.username,
              email: userDetails.attributes["email"],
              first_name: userDetails.attributes["custom:first_name"],
              last_name: userDetails.attributes["custom:last_name"],
              organization: userDetails.attributes["custom:organization"],
              user_role: userDetails.attributes["custom:user_type"],
              total_licenses: userDetails.attributes["custom:total_licenses"],
              license_type: userDetails.attributes["custom:license_type"],
              tier_1_devices: userDetails.attributes["custom:tier_1_devices"],
              role: "Admin",
              token:
                "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJDb2RlcnRoZW1lIiwiaWF0IjoxNTU1NjgyNTc1LCJleHAiOjE1ODcyMTg1NzUsImF1ZCI6ImNvZGVydGhlbWVzLmNvbSIsInN1YiI6InRlc3QiLCJmaXJzdG5hbWUiOiJIeXBlciIsImxhc3RuYW1lIjoiVGVzdCIsIkVtYWlsIjoidGVzdEBoeXBlci5jb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4ifQ.8qHJDbs5nw4FBTr3F8Xc1NJYOMSJmGnRma7pji0YwB4",
            };

            // Storing the ID Token

            getUserDetailsFromDb({
              organization: userDetails.attributes["custom:organization"],
              username: userDetails.username,
              id: userDetails.attributes["sub"],
            })
              .then((value) => {
                value.data.data["token"] =
                  userDetails.signInUserSession.accessToken.jwtToken;
                value.data.data["role"] = "Admin";
                value.data.data["id"] = userDetails.attributes["sub"];
                setCookie(
                  JSON.stringify(
                    { token: userDetails.signInUserSession.idToken.jwtToken },
                    null,
                    2
                  )
                ).then((d) => {
                  resolve({ ok: true, json: () => value.data.data });
                });
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            console.log(err);
            if (err.code == "PasswordResetRequiredException") {
              err.message = err.code;
            } else if (err.code == "UserNotConfirmedException") {
              err.message = err.code;
            }
            if (err.message == "Cannot read property 'sub' of undefined")
              err.message =
                "Your account needs to be activated through the mobile app. Please download the Glacier Chat app and login.";
            reject(err.message);
          });

        return;
      }

      // register
      if (url.endsWith("/users/register") && opts.method === "POST") {
        // get parameters from post request
        console.log("BODY CALLED");
        let params = JSON.parse(opts.body);
        let responseData = "";

        const { username, email, password, phone_number } = params;
        var user = {
          username: params.user_name,
          email: params.email,
          first_name: params.first_name,
          last_name: params.last_name,
          organization: params.organization,
          password: params.password,
          user_type: "user",
          user_name: params.user_name,
        };
        Auth.signUp({
          username: user.user_name,
          password: user.password,
          attributes: {
            email: user.email,
            "custom:first_name": user.first_name, // optional - E.164 number convention
            "custom:last_name": user.last_name,
            "custom:organization": user.organization,
            "custom:total_licenses": "25",
            "custom:license_type": "Per User",
            "custom:tier_1_devices": "224",
            "custom:user_type": user.user_type,
            "custom:isUserAdminCreated": "false",
            // other custom attributes
          },
          validationData: [], //optional
        })
          .then((data) => (responseData = data))
          .then(() => {
            let p_number = params.phone_number;
            let newUser = {
              id: responseData.userSub,
              username: responseData.user.username,
              email: responseData.user.username,
              userConfirmed: responseData.userConfirmed,
              first_name: user.first_name,
              last_name: user.last_name,
              role: "Admin",
            };
            //users.push( newUser )

            let responseJson = {
              id: newUser.id,
              username: newUser.username,
              email: newUser.email,
              userConfirmed: newUser.userConfirmed,
              role: newUser.role,
            };

            addUser(user)
              .then((response) => {
                resolve({ ok: true, json: () => responseJson });
              })
              .catch((err) => {
                reject(err.message);
              });
          }) // switches Sign Up to Verification
          .catch((err) => {
            console.log(err);
            reject(err.message);
          });
        return;
        // add new users
      }

      // forget password
      if (url.endsWith("/users/password-reset") && opts.method === "POST") {
        // get parameters from post request
        let params = JSON.parse(opts.body);

        // find if any user matches login credentials
        let filteredUsers = users.filter((user) => {
          return user.username === params.username;
        });

        if (filteredUsers.length) {
          let responseJson = {
            message:
              "We've sent you a link to reset password to your registered email.",
          };
          resolve({ ok: true, json: () => responseJson });
        } else {
          // else return error
          reject(
            "Sorry, we could not find any registered user with entered username"
          );
        }
        return;
      }

      // get users
      if (url.endsWith("/users") && opts.method === "GET") {
        // check for fake auth token in header and return users if valid, this security is implemented server side in a real application
        if (
          opts.headers &&
          opts.headers.Authorization ===
            "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJDb2RlcnRoZW1lIiwiaWF0IjoxNTU1NjgyNTc1LCJleHAiOjE1ODcyMTg1NzUsImF1ZCI6ImNvZGVydGhlbWVzLmNvbSIsInN1YiI6InRlc3QiLCJmaXJzdG5hbWUiOiJIeXBlciIsImxhc3RuYW1lIjoiVGVzdCIsIkVtYWlsIjoidGVzdEBoeXBlci5jb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4ifQ.8qHJDbs5nw4FBTr3F8Xc1NJYOMSJmGnRma7pji0YwB4"
        ) {
          resolve({ ok: true, json: () => users });
        } else {
          // return 401 not authorised if token is null or invalid
          reject("Unauthorised");
        }

        return;
      }

      // confirm signup
      if (url.endsWith("/users/confirm") && opts.method === "POST") {
        let params = JSON.parse(opts.body);
        console.log("IN CONFIRM BOX", params);
        let { username, code } = params;
        let responseData;
        Auth.confirmSignUp(username, code, {
          // Optional. Force user confirmation irrespective of existing alias. By default set to True.
          forceAliasCreation: true,
        })
          .then((data) => (responseData = data))
          .then(() => {
            console.log("User is confirmed ", responseData);
            let user_name = username;
            let user = {
              username: user_name,
              userConfirmed: true,
            };
            resolve({ ok: true, json: () => user });
          })
          .catch((err) => {
            console.log(err);
            reject(err.message);
          });

        // // check for fake auth token in header and return users if valid, this security is implemented server side in a real application
        // if (opts.headers && opts.headers.Authorization === 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJDb2RlcnRoZW1lIiwiaWF0IjoxNTU1NjgyNTc1LCJleHAiOjE1ODcyMTg1NzUsImF1ZCI6ImNvZGVydGhlbWVzLmNvbSIsInN1YiI6InRlc3QiLCJmaXJzdG5hbWUiOiJIeXBlciIsImxhc3RuYW1lIjoiVGVzdCIsIkVtYWlsIjoidGVzdEBoeXBlci5jb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4ifQ.8qHJDbs5nw4FBTr3F8Xc1NJYOMSJmGnRma7pji0YwB4') {
        //     resolve({ ok: true, json: () => users });
        // } else {
        //     // return 401 not authorised if token is null or invalid
        //     reject('Unauthorised');
        // }

        return;
      }

      // pass through any requests not handled above
      realFetch(url, opts).then((response) => resolve(response));

      // }, 500);
    });
  };
}
